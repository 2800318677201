export default function initSearch() {
	const searchResetButton = document.querySelector(
		'.search-form__reset-button'
	);

	searchResetButton.addEventListener('click', (e) => {
		e.preventDefault();
		const target = e.target;
		const input = target
			.closest('.search-form__input-wrapper')
			.querySelector('input');

		input.value = '';
		input.focus();
	});
}
