export default function initTaboola() {

  /**
   * update the taboola IDS to prevent having double IDs in DOM
   */
  function updateTaboolaIDs() {

    //update IDs for taboola end of article add suffix -1, -2 etc.
    const taboolas = document.getElementsByClassName("ad--taboola-eoa");
    for (let i = 0; i < taboolas.length; i++) {
      let taboola = taboolas[i];
      if (i >= 1 && taboola.id == 'taboola-end-of-article') {
        taboola.id = 'taboola-end-of-article' + '-' + i;
      }
    }
  }

  $('body').on('infiniteScroll', function() {
    updateTaboolaIDs();
    $('body').trigger('cmpEvent');
  });
}
