// import './_old-prod';
// import './_old-scripts';
//import '../scss/main.scss';
import Header from './components/header';
import initStickyElement from './components/stickyElement';
import initSearch from './components/search';
import initSliders from './components/slider';
import initIntextSliders from './components/intextSlider.js';
import initGallerySlider from './components/gallery';
import initAds from './components/ads';
import initTaboola from './components/taboola';

function getScrollbarWidth() {
	if (!document.body) return;
	// Creating invisible container
	const outer = document.createElement('div');
	outer.style.visibility = 'hidden';
	outer.style.overflow = 'scroll'; // forcing scrollbar to appear
	document.body.appendChild(outer);

	// Creating inner element and placing it in the container
	const inner = document.createElement('div');
	outer.appendChild(inner);

	// Calculating difference between container's full width and the child width
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

	// Removing temporary elements from the DOM
	outer.parentNode.removeChild(outer);
	return scrollbarWidth;
}

function refreshScrollbarWidth() {
	document.documentElement.style.setProperty(
		'--scrollbar-width',
		getScrollbarWidth() + 'px'
	);
}

document.addEventListener('DOMContentLoaded', () => {
	refreshScrollbarWidth();
	new Header();

	initAds();
	initSearch();
	initSliders();
	initIntextSliders();
	initGallerySlider();
	initTaboola();
	initStickyElement();
});
