/**
 * This adds a data attribute to the <html> element that can be used and addressed in css
 */
export default function stickyElement() {
	//https://css-tricks.com/styling-based-on-scroll-position/

	// The debounce function receives our function as a parameter
	const debounce = (fn) => {
		// This holds the requestAnimationFrame reference, so we can cancel it if we wish
		let frame;
		// The debounce function returns a new function that can receive a variable number of arguments
		return (...params) => {
			// If the frame variable has been defined, clear it now, and queue for next frame
			if (frame) {
				cancelAnimationFrame(frame);
			}
			// Queue our function call for the next frame
			frame = requestAnimationFrame(() => {
				// Call our function and pass any params we received
				fn(...params);
			});
		};
	};

	// Reads out the scroll position and stores it in the data attribute
	// so we can use it in our stylesheets
	const makeSticky = (stickyElement) => {
		document.documentElement.dataset.scroll = window.scrollY;
		stickyElement = document.querySelector('.ad-wrapper--sky_right');
		if (stickyElement) {
			let stickyPos = stickyElement.getBoundingClientRect();

			if (stickyPos.top <= 135) {
				stickyElement.classList.add('sticky');
			} else {
				stickyElement.classList.remove('sticky');
			}
		}
	};

	const stickyElement = document.querySelector('.ad--sky_right');
	const navbar = document.querySelector('nav.navbar-fixed-top');

	// Listen for new scroll events, here we debounce our `storeScroll` function
	document.addEventListener('scroll', debounce(makeSticky, stickyElement), {
		passive: true,
	});

	// Update scroll position for first time
	makeSticky();
}
