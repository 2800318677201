//for now, there is no animation
export default class Header {
	constructor() {
// 		this.initFixedHeader();
// 		this.initNavigationOverflowWatcher();
	}
// 	initFixedHeader() {
// 		const primaryHeader = document.querySelector('.navbar-fixed-top');
// 		const scrollWatcher = document.createElement('div');
//
// 		scrollWatcher.setAttribute('data-scroll-watcher', '');
// 		primaryHeader.before(scrollWatcher);
//
// 		const navObserver = new IntersectionObserver((entries) => {
// 			primaryHeader.classList.toggle(
// 				'sticking',
// 				!entries[0].isIntersecting
// 			);
// 			document.body.classList.toggle(
// 				'has-sticking-header',
// 				!entries[0].isIntersecting
// 			);
// 		});
//
// 		navObserver.observe(scrollWatcher);
// 	}
// 	initNavigationOverflowWatcher() {
// 		const navigation = document.querySelector('#navbar');
// 		const navigationItems = document.querySelector(
// 			'#navbar .navigation__items'
// 		);
//
// 		const resize_ob = new ResizeObserver(function (entries) {
// 			navigation.style.setProperty(
// 				'--scrollbar-height',
// 				`${navigation.clientHeight - navigationItems.clientHeight}px`
// 			);
// 		});
// 		resize_ob.observe(navigation);
// 	}
}
