const prevArrow = `
	<button type="button" class="slick-prev button--red button--icon-only">
		<svg fill="none" viewBox="0 0 40 40">
			<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M23.125 26.25 16.875 20l6.25-6.25"/>
		</svg>
	</button>`;

const nextArrow = `
	<button type="button" class="slick-next button--red button--icon-only">
		<svg fill="none" viewBox="0 0 40 40">
			<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m16.875 13.75 6.25 6.25-6.25 6.25"/>
		</svg>
	</button>`;

export default function initSliders() {

	// HANDPICKED SLIDER
	const allSlider = document.querySelectorAll('.category-slider');

	Array.from(allSlider).forEach(function (slider) {
		const arrowsContainer = slider
			.closest('.category-slider__wrapper')
			.querySelector('.category-slider__arrows');

		$(slider).slick({
			dots: false,
			arrows: true,
			prevArrow,
			nextArrow,
			appendArrows: arrowsContainer,
			adaptiveHeight: true,
		});
	});

	// CATEGORY TEASER GROUPS
	const allCategoryTeaserSlider = document.querySelectorAll(
		'.category-teaser-slider'
	);

	Array.from(allCategoryTeaserSlider).forEach(function (slider) {
		const categoryTeaserSliderWrapper = slider.closest(
			'.category-teaser-slider__wrapper'
		);
		let arrowsWrapper = undefined;
		if (categoryTeaserSliderWrapper) {
			arrowsWrapper =
				categoryTeaserSliderWrapper.querySelector(
					'.category-teaser-slider__arrows'
				) || undefined;
		}

		const settings = {
			dots: false,
			adaptiveHeight: false,
			appendArrows: arrowsWrapper,
			arrows: !!arrowsWrapper,
			prevArrow: !!arrowsWrapper ? prevArrow : undefined,
			nextArrow: !!arrowsWrapper ? nextArrow : undefined,
			responsive: [
				{
					breakpoint: 9999,
					settings:
						slider.children.length <= 3
							? 'unslick'
							: {
									slidesToShow: 3,
							  },
				},
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		};
		const sl = $(slider).slick(settings);

		$(window).on('resize', function () {
			if ($(window).width() < 1200 && !sl.hasClass('slick-initialized')) {
				$(slider).slick(settings);
			}
		});

		//re-init sliders added by infinite scroll function
		$('body').on('infiniteScroll', function () {
			let updatSettings = settings;

			$('.category-teaser-slider:not(.slick-initialized)').each(function( index, element ) {
				let arrowsWrapper = $(element).closest('.category-teaser-slider__wrapper').find('.category-teaser-slider__arrows').first();
				updatSettings.appendArrows = arrowsWrapper;
				updatSettings.arrows = !!arrowsWrapper;
				updatSettings.prevArrow = !!arrowsWrapper ? prevArrow : undefined;
				updatSettings.nextArrow = !!arrowsWrapper ? nextArrow : undefined;

				$(element).slick(updatSettings);
			});
		});
	});

	// Header Slider
	const headerSlider = document.querySelectorAll(
		'.header-slider--background'
	);

	Array.from(headerSlider).forEach(function (slider) {
		slider.addEventListener('click', function (e) {
			if (!slider.slick) return;

			const currentItemBox = slider
				.querySelector('.slick-current')
				.getBoundingClientRect();

			if (e.offsetX < currentItemBox.left) {
				// slide prev
				slider.slick.slickPrev();
			} else if (e.offsetX > currentItemBox.left + currentItemBox.width) {
				// slide next
				slider.slick.slickNext();
			}
		});
	});
}
