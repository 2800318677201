export default function initIntextSlider() {
	const allIntextSlider = document.querySelectorAll('.pimcore_area_intextgallery');

	const prev = `
		<button type="button" class="slick-button slick-prev button--red button--icon-only">
			<svg fill="none" viewBox="0 0 40 40">
				<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M23.125 26.25 16.875 20l6.25-6.25"/>
			</svg>
		</button>`;

	const next = `
	<button type="button" class="slick-button slick-next button--red button--icon-only">
		<svg fill="none" viewBox="0 0 40 40">
			<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m16.875 13.75 6.25 6.25-6.25 6.25"/>
		</svg>
	</button>`;

	Array.from(allIntextSlider).forEach(function (gallery) {
		const intextSlider = gallery.querySelectorAll('.intext-gallery__images-slider');

		$(intextSlider).slick({
			dots: false,
			arrows: true,
			adaptiveHeight: true,
			prevArrow: prev,
			nextArrow: next
		}).on('afterChange', (event, slick, currentSlide, nextSlide) => {

			// update gallery slider counter
			let pos = 1 + currentSlide;
			if (pos < 10) pos = `0${pos}`;

			 gallery
			 	.querySelector('.intext-gallery__gallery-counter')
			 	.setAttribute('data-before', pos);

			//
			// Analytics VirtualPageview
			//
			dataLayer.push({
				event: 'VirtualPageviewGalerie', // BDF-33 requires a PageView to be tracked on swipe
				virtualPageURL: window.location.pathname,
				virtualPageTitle: document.title,
			});

			//IVW track a pageview on gallery slide
			if (typeof IOMm !== 'undefined') {
				IOMm('pageview', iomm_pageview_data);
			}
		});
	});
}
