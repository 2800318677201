const prevArrow = `<button type="button" class="slick-prev button--red button--icon-only"><svg fill="none" viewBox="0 0 40 40">
											<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M23.125 26.25 16.875 20l6.25-6.25"/>
										</svg></button>`;

const nextArrow = `<button type="button" class="slick-next button--red button--icon-only"><svg fill="none" viewBox="0 0 40 40">
											<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m16.875 13.75 6.25 6.25-6.25 6.25"/>
										</svg></button>`;

function sanitizer(s) {
	s = s.toLowerCase();
	s = s
		.replace(/ä/g, 'ae')
		.replace(/ö/g, 'oe')
		.replace(/ü/g, 'ue')
		.replace(/ß/g, 'ss'); // Umlaute
	s = s
		.replace(/[^a-z0-9-]+/g, '-')
		.replace(/[\-]{2,}/g, '-')
		.replace(/(^-|-$)/, ''); // Sonderzeichen
	return s;
}

function updateUrl(headline) {
	var sanitizedHeadline = sanitizer(headline);

	history.replaceState(undefined, undefined, `#${sanitizedHeadline}`);
}

export default function initGallerySlider() {
	const allGalleries = document.querySelectorAll('.gallery');

	Array.from(allGalleries).forEach(function (gallery) {
		const imageSlider = gallery.querySelectorAll('.gallery__images-slider');
		const arrowsContainer = gallery.querySelectorAll('.gallery__arrows');
		const metaDataSlider = gallery.querySelectorAll(
			'.gallery__metadata-slider'
		);

		$(imageSlider).slick({
			dots: false,
			arrows: false,
			adaptiveHeight: true,
			asNavFor: metaDataSlider,
		});

		$(metaDataSlider)
			.slick({
				arrows: true,
				adaptiveHeight: true,
				asNavFor: imageSlider,
				fade: true,
				prevArrow,
				nextArrow,
				appendArrows: arrowsContainer,
			})
			.on('afterChange', (event, slick, currentSlide, nextSlide) => {
				// update gallery slider counter
				let pos = 1 + currentSlide;
				if (pos < 10) pos = `0${pos}`;
				gallery
					.querySelector('.gallery__counter')
					.setAttribute('data-before', pos);

				if (
					typeof reloadSparkAdsOnGalleries === 'boolean' &&
					reloadSparkAdsOnGalleries
				) {
					// DEV-8032
					if (
						typeof Spark.definitionService.refresh === 'function' &&
						(1 + nextSlide) % 3 == 0
					) {
						console.log('fire refresh');
						try {
							Spark.cmd.push(function () {
								Spark.definitionService.refresh(); // Reload Spark ads
							});
						} catch (e) {}
					}
				}

				if (typeof IOMm !== 'undefined') {
					// SZM 2
					IOMm('pageview', iomm_pageview_data);
				}

				//
				// VirtualPageview
				//
				dataLayer.push({
					event: 'VirtualPageviewGalerie', // VirtualPageview
					virtualPageURL: window.location.pathname,
					virtualPageTitle: document.title,
				});

				updateUrl(
					slick.$slides
						.get(currentSlide)
						.querySelector('.gallery-headline').innerText
				);
			});
	});
}
