export default function initAds() {
	const mobileBreakpoint = 1359; //everything below tablet breakpoint loads mobile ads
	const initiallyLoadedAds = ['mr1', 'sky1', 'sb1', 'pos1']; //these are the ads that must be initially assigned to Spark
	const adSelector = 'ad';

	const mapping = {
		mobile: {
			pos1: 'pos1',
			pos2: 'pos2',
			pos3: 'pos3',
			posn: 'posn',
			po1: 'po1',
			banner: 'pos1',
			mrec: false,
			mrec_btf: false,
			anchorAd: 'anchor1',
			superbanner: false,
			navbar_right: false,
			navbar_left: false,
			billboard: false,
			billboard_btf: false,
			billboard_btf_2: false,
			billboard_btf_3: false,
			sky_right: false,
			sky_left: false,
			outofpage: false,
		},
		desktop: {
			banner: false,
			mrec: 'mr1',
			mrec_btf: false,
			pos3: false,
			anchorAd: false,
			superbanner: 'sb1',
			navbar_right: 'menu1',
			navbar_left: 'menu2',
			bibo1: 'bibo1',
			bibo2: 'bibo2',
			bibo3: 'bibo3',
			bibon: 'bibon',
			billboard: 'bibo1',
			billboard_btf: 'bibo2',
			billboard_btf_2: 'bibo3',
			billboard_btf_3: 'bibon',
			sky_right: 'sky1',
			sky_left: false,
			outofpage: 'outofpage',
			po1: 'po1',
		},
	};

	const adSlots = {
		mobile: [
			{
				name: 'pos1',
				id: 'pos1',
				sizes: [
					[320, 50],
					[320, 75],
					[320, 100],
					[300, 100],
					[300, 150],
					[320, 150],
					[300, 250],
					[336, 280],
				],
				targetings: {
					pos: 'pos1',
				},
			},
			{
				name: 'pos2',
				id: 'pos2',
				sizes: [
					[320, 50],
					[320, 75],
					[320, 100],
					[300, 100],
					[300, 150],
					[320, 150],
					[300, 250],
					[336, 280],
				],
				targetings: {
					pos: 'pos2',
				},
			},
			{
				name: 'pos3',
				id: 'pos3',
				sizes: [
					[320, 50],
					[320, 75],
					[320, 100],
					[300, 100],
					[300, 150],
					[320, 150],
					[300, 250],
					[336, 280],
				],
				targetings: {
					pos: 'pos3',
				},
			},
			{
				name: 'posn',
				id: 'posn',
				sizes: [
					[320, 50],
					[320, 75],
					[320, 100],
					[300, 100],
					[300, 150],
					[320, 150],
					[300, 250],
					[336, 280],
				],
				targetings: {
					pos: 'posn',
				},
			},
			{
				name: 'po1',
				id: 'po1',
				sizes: [[300, 400]],
				targetings: {
					pos: 'po1',
				},
			},
		],
		desktop: [
			{
				name: 'menu1',
				id: 'menu1',
				sizes: [[300, 90]],
				targetings: {
					pos: 'menu1',
				},
				headerbidding: false,
			},
			{
				name: 'menu2',
				id: 'menu2',
				sizes: [[300, 90]],
				targetings: {
					pos: 'menu2',
				},
				headerbidding: false,
			},
			{
				name: 'sb1',
				id: 'sb1',
				sizes: [
					[970, 250],
					[800, 250],
					[728, 90],
					[1280, 90],
					[700, 90],
				],
				targetings: {
					pos: 'sb1',
				},
			},
			{
				name: 'sky1',
				id: 'sky1',
				sizes: [
					[120, 600],
					[160, 600],
					[200, 600],
					[300, 600],
					[300, 1050],
					[500, 1000],
				],
				targetings: {
					pos: 'sky1',
				},
			},
			{
				name: 'mr1',
				id: 'mr1',
				sizes: [[300, 250]],
				targetings: {
					pos: 'mr1',
				},
			},
			{
				name: 'bibo1',
				id: 'bibo1',
				sizes: [
					[800, 250],
					[728, 90],
				],
				targetings: {
					pos: 'bibo1',
				},
			},
			{
				name: 'bibo2',
				id: 'bibo2',
				sizes: [
					[800, 250],
					[728, 90],
				],
				targetings: {
					pos: 'bibo2',
				},
			},
			{
				name: 'bibo3',
				id: 'bibo3',
				sizes: [
					[800, 250],
					[728, 90],
				],
				targetings: {
					pos: 'bibo3',
				},
			},
			{
				name: 'bibon',
				id: 'bibon',
				sizes: [
					[800, 250],
					[728, 90],
				],
				targetings: {
					pos: 'bibon',
				},
			},
			{
				name: 'outofpage',
				id: 'outofpage',
				targetings: {
					pos: 'outofpage',
				},
			},
			{
				name: 'po1',
				id: 'po1',
				sizes: [[300, 400]],
				targetings: {
					pos: 'po1',
				},
			},
		],
	};

	/**
	 * check if device tipe has changed on resize
	 * @param deviceType
	 * @returns {boolean}
	 */
	function hasDeviceTypeChanged(deviceType) {
		let hasChanged = window.deviceType != deviceType;
		window.deviceType = deviceType;
		return hasChanged;
	}

	/**
	 * get random int
	 * @param min
	 * @param max
	 * @returns {number}
	 */
	function getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
	}

	/**
	 * update ads after resize if nessesary
	 */
	function updateAds() {
		let clientWidth =
			window.innerWidth ||
			window.document.documentElement.clientWidth ||
			window.document.body.clientWidth;

		if (clientWidth <= mobileBreakpoint) {
			if (hasDeviceTypeChanged('mobile')) {
				loadAds();
			}
		} else {
			if (hasDeviceTypeChanged('desktop')) {
				loadAds();
			}
		}
	}

	/**
	 * return mapped and filtered adSlot settings by name
	 * @param mapping
	 * @param slot
	 * @param device
	 * @returns {*|*[]}
	 */
	function getSlotInfo(adSlots, mapping, slotName, device) {
		const mappedSlotName = mapping[device][slotName];
		console.log(
			'get info for ' +
				slotName +
				': mapped ' +
				mappedSlotName +
				' on ' +
				device
		);

		if (mappedSlotName == false || typeof mappedSlotName === 'undefined') {
			//console.log('no mapped entry for ' + slotName );
			return undefined;
		}

		return adSlots[device].filter((obj) => {
			if (!obj.name === mappedSlotName) {
				console.log('missing entry for ' + obj.name);
			}
			return obj.name === mappedSlotName;
		});
	}

	/**
	 * replace ids for elements that have an counting number like pos1, pos2, pos3, posn, bibo1 etc.
	 * @param placeholders
	 */
	function updateCountablePlaceholder(placeholders) {
		//these are ids that come in the template as posx and need to be renamed to po1, pos, po3, pon
		let countableIds = { pos: 0, bibo: 0 };

		for (let i = 0; i < placeholders.length; i++) {
			let adContainer = placeholders[i];
			let type = adContainer.dataset.pos; //pos or bibo

			if (
				Object.keys(countableIds).indexOf(adContainer.dataset.pos) > -1
			) {
				countableIds[adContainer.dataset.pos]++;
				if (countableIds[adContainer.dataset.pos] <= 3) {
					//add the numbers suffix
					adContainer.dataset.pos =
						adContainer.dataset.pos +
						countableIds[adContainer.dataset.pos];
				} else {
					//add n as suffix when number > 3
					adContainer.dataset.pos = adContainer.dataset.pos + 'n';
				}
			}
		}
	}

	/**
	 * filter adSlots and return only used slots
	 * @param adSlots
	 * @returns {*}
	 */
	function filterSlotsForDomNodes(adSlots) {
		const placeholders = document.getElementsByClassName(adSelector);
		const windowWidth =
			window.innerWidth ||
			window.document.documentElement.clientWidth ||
			window.document.body.clientWidth;
		const device = windowWidth <= mobileBreakpoint ? 'mobile' : 'desktop';
		let retSlots = [];

		for (let i = 0; i < adSlots.length; i++) {
			let adSlot = adSlots[i];
			for (let j = 0; j < placeholders.length; j++) {
				if (
					adSlots[i].name ==
						mapping[device][placeholders[j].dataset.pos] &&
					!retSlots.includes(adSlots[i]) &&
					initiallyLoadedAds.includes(adSlots[i].name)
				) {
					adSlots[i].id = placeholders[j].id;
					retSlots.push(adSlots[i]);
				}
			}
		}

		console.log('found these adslots in DOM', retSlots);
		return retSlots;
	}

	/**
	 * init Spark
	 */
	function initSpark(adSlots, mapping) {
		console.log('init spark');

		const windowWidth =
			window.innerWidth ||
			window.document.documentElement.clientWidth ||
			window.document.body.clientWidth;
		const environment = windowWidth <= mobileBreakpoint ? 'mob' : 'sta';
		const device = windowWidth <= mobileBreakpoint ? 'mobile' : 'desktop';
		const siteName = {
			mob: 'mob_bildderfrau',
			sta: 'sta_bildderfrau',
		};
		const placeholders = document.getElementsByClassName(adSelector);
		updateCountablePlaceholder(placeholders);
		const adSlotsForDevice = filterSlotsForDomNodes(adSlots[device]);

		//first part of the URL
		let zoneName =
			window.location.pathname === '/'
				? 'homepage'
				: window.location.pathname.split('/')[1];

		//set zoneName for pages without subpages on top level (e.g. imprint) to other
		if (pageUrlDepth === 2 && pageNumChildren === 0) {
			zoneName = 'other';
		}

		//set zoneName for 404 page to 'other'
		if (document.querySelectorAll('.page404').length > 0) {
			zoneName = '404';
		}

		const others = [
			'404',
			'tags',
			'archiv',
			'bdf',
			'newsletter',
			'autoren',
			'suche',
			'undefined',
			'impressum-datenschutz',
			'promi-party',
			'familie-leben',
			'horoskope'
		];
		if (others.includes(zoneName)) {
			zoneName = 'other';
		}

		sparkConfig.cmpParams.cmp_imprinturl =
			'https://www.bildderfrau.de/impressum-datenschutz/'; //todo: replace by property?
		sparkConfig.cmpParams.cmp_privacyurl =
			'https://www.bildderfrau.de/impressum-datenschutz/';
		sparkConfig.adslots = adSlotsForDevice;
		sparkConfig.environment = environment; //nur zur sicherheit
		sparkConfig.targetings.environment = environment;
		sparkConfig.siteName = siteName[environment];
		sparkConfig.zoneName = zoneName;

		//Testausgabe der Config
		console.log('Spark Konfiguration', sparkConfig);
		var Spark = document.spark || { cmd: [] };

		Spark.cmd.push(function () {
			Spark.init(sparkConfig);
		});
		Spark.cmd.push(function () {
			Spark.start();
		});
	}

	/**
	 * iterate over ad container and load mapped ad settings into these containers
	 */
	function loadAds() {
		console.log('load ads for devicetype ', window.deviceType);
		let ads = [];
		let slotInfo = false;
		const placeholders = document.getElementsByClassName(adSelector);

		for (let i = 0; i < placeholders.length; i++) {
			let adContainer = placeholders[i];
			let adContainerID = '';

			//add id to the element if there is none
			if (adContainer.id === '') {
				let adContainerID =
					'ad-' + getRandomInt(1, 99999999999).toString();
				adContainer.setAttribute('id', adContainerID);
				console.log('setze id ' + adContainerID);
			} else {
				adContainerID = adContainer.id;
			}

			slotInfo = getSlotInfo(
				adSlots,
				mapping,
				adContainer.dataset.pos,
				window.deviceType
			);

			if (
				slotInfo &&
				slotInfo.length &&
				!initiallyLoadedAds.includes(slotInfo[0].name)
			) {
				//push Ad to Spark
				document.spark.cmd.push(function () {
					if (typeof slotInfo != 'undefined') {
						let sparkData = {
							name: slotInfo[0].name,
							id: adContainerID,
							targetings: slotInfo[0].targetings,
						};

						if ('outofpage' != slotInfo[0].name) {
							sparkData.sizes = slotInfo[0].sizes;
						}

						try {
							console.log('Pushing to Spark', sparkData);
							document.spark.definitionService.defineSlot(
								sparkData
							);
							document.spark.definitionService.displaySlot(
								adContainerID
							);
						} catch (e) {
							console.error(e);
						}
					} else {
						console.warn('slotInfo is empty');
					}
				});
			} else {
				console.log(
					'No Information found for, or skipped adblock: ',
					adContainer.dataset.pos,
					'on',
					window.deviceType
				);
			}
		}
	}
	//initialize spark
	initSpark(adSlots, mapping);

	//start script initially
	updateAds();

	//onresize funktioniert nicht
	// window.addEventListener('resize', (event) => {
	//   updateAds();
	// });
}
